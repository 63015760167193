"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _request = require("./request");

var _request2 = _interopRequireDefault(_request);

var _config = require("./config");

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var statisticalCodeMain = /** @class */function () {
    function statisticalCodeMain() {
        this.wmRequest = new _request2.default();
        this.init();
    }
    statisticalCodeMain.prototype.init = function () {
        var _this = this;
        setTimeout(function () {
            return _this.statisticsEvent();
        }, 300);
    };
    statisticalCodeMain.prototype.statisticsEvent = function () {
        /**
        * 统计安装包下载次数
        * 情况一：只有安装包的a标签
        * 情况二：在js中唤起应用商店
        */
        var that = this;
        // 数据
        var data = {
            g_id: null,
            package_name: null,
            url: document.location.href,
            mark: null,
            platform: Number(getCurrentPlatform())
        };
        // 获取带有安装包的a标签
        var statisticsButton = document.querySelectorAll('a');
        var herfButon = [];
        statisticsButton.forEach(function (i) {
            // 将有安装包的a标签放入数组
            var split = i.href.split('.');
            if (split[split.length - 1] == 'apk' || split[split.length - 1] == 'exe' || split[split.length - 1] == 'dmg') {
                herfButon.push(i);
            }
        });
        // 获取带有download-click类名的标签;处理落地页中的a标签
        var downloadButton = document.querySelectorAll('.download-click');
        downloadButton.forEach(function (i) {
            return herfButon.push(i);
        });
        herfButon.forEach(function (i, index) {
            return i.setAttribute('data-markid', "".concat(index));
        });
        // 在点击后调用统计接口
        herfButon.forEach(function (i) {
            if (!i.classList.contains('download-click')) {
                i.addEventListener('click', function () {
                    var g_id = i.getAttribute('data-gid');
                    // 获取操作手册页面ID
                    if (!i.getAttribute('data-gid')) {
                        var domId = document.querySelector('.statistics');
                        // console.log(domId.getAttribute('data-gid'));
                        g_id = domId.getAttribute('data-gid');
                    }
                    // 获取参数
                    data['g_id'] = Number(g_id);
                    data['package_name'] = i.getAttribute('href');
                    data['mark'] = i.getAttribute('data-markid');
                    that.wmRequest.ajax({
                        type: 'POST',
                        url: _config2.default.WM_DOWNLOADBUTTONRECORD,
                        data: data
                    }).then(function (res) {
                        if (res.code == 200) {
                            console.log('statistical success');
                        } else {
                            console.error(res.code);
                        }
                    });
                });
            }
        });
        function getCurrentPlatform() {
            var platform = navigator.platform.toLowerCase();
            if (platform.includes('win')) {
                return 1; // Windows
            } else if (platform.includes('mac')) {
                return 2; // MacOS
            } else if (platform.includes('android')) {
                return 3; // Android
            } else if (platform.includes('iphone') || platform.includes('ipad') || platform.includes('ipod')) {
                return 4; // iOS
            }
        }
    };
    return statisticalCodeMain;
}();
exports.default = statisticalCodeMain;